body {
  overflow-y: scroll;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body a {
  -webkit-text-decoration: none;
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body {
  overscroll-behavior-x: none;
}

.ka-wrapper, .ka-content {
  height: 100%
}

/* video::-webkit-media-controls-fullscreen-button {
  display: none;
} */


@keyframes flash {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

.new-message {
  animation: flash 1s infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fadeOut {
  0% {
    background-color: #F5F9FF;
  }
  100% {
    background-color: transparent;
  }
}

/* 禁用点击高亮效果 */
* {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

